.podcast_div{
    margin-bottom: 250px;
}
@media(max-width: 1200px) {
    .podcast_div{
        padding: 20px;
        margin-bottom: 120px;
    }
  }
  @media(max-width: 700px) {
    .podcast_div_flex{
        display: flex !important;
    flex-direction: column;
    }
  }
  