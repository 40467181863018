.contact_page{
    background-color: #ffffff;
}
.contact-section{
    margin-bottom: 100px;
    padding: 60px 100px;
    position: relative;
    background-color: #ffffff;
    margin-bottom: 250px;
}
.contact_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
}
.contact_div .left_div{
    width: 45%;
    display: flex;
    flex-direction: column;
}
.contact_div .left_div h1{
    font-family: "Alegreya";
    font-weight: 700;
    font-size: 54px;
    margin-bottom: 10px;
}
.contact_div .left_div h1 span{
    color: #F47216;
}
.contact_div .left_div p{
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin-bottom: 40px;
}
.contact_div .left_div input{
    border: 1px solid #E0E0E0;
    padding: 12px, 20px, 12px, 20px;
    padding: 10px;
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: "Montserrat";
}
/* .contact_div .left_div input:last-child{
    margin-bottom: 0px;
} */
.contact_div .right_div{
    width: 45%;
    display: flex;
}
.contact_div .right_div iframe {
    z-index: 1;
}
.send-btn{
    background: #F47216;
    padding: 12px 24px 12px 24px;
    color: #ffffff;
    font-family: "Montserrat";
    font-weight: 700;
}
.contact_details{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.details_div{
    display: flex;
    font-family: "Montserrat";
    display: flex;
    align-items: center;
}
.details_div img{
    width: 28px;
    height: 28px;
}
.details_div .contact_here{
    margin-left: 15px;
}
.details_div .contact_here h4{
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    font-weight: 700;
}
.details_div .contact_here span{
    font-size: 13px;
    font-weight: 400;
    color: #DD5471;
}
.backgroud_div{
    display: block;
    height: 100%;
    background:
linear-gradient(269.81deg, #F19843 0.14%, #FBBA7B 47.21%, #F29C46 99.82%);

    width: 30%;
    position: absolute;
    top: 0;
    /* display: inline-block; */
    right: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
@media(max-width: 1000px) {
    .contact-section .contact_div .left_div{
        width: 100%;
    }
    .contact-section .contact_div .right_div{
        width: 100%;
    }
    .contact-section{
        padding: 20px 20px;
        margin-bottom: 0px;
        padding-bottom: 100px;
    }
    .contact_div{
        flex-direction: column; gap: 30px;
        padding: 20px;
    }
    .backgroud_div{
        display: none;
    }
    .contact_div .left_div p{
        margin-bottom: 20px;
    }
    .contact_div .left_div h1{
     font-size: 34px;
    }
  }