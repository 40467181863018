@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RhodiumLibreRegular.ttf");
  font-family: "Rhodium Libre";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OutfitRegular.ttf");
  font-family: "Outfit";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/AlegreyaRomanMedium.ttf");
  font-family: "Alegreya";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsMedium.ttf");
  font-family: "Poppins";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/UnboundedRegular.ttf");
  font-family: "Unbounded";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/AlegreyaRomanExtraBold.ttf");
  font-family: "Alegreya";
  font-weight: 800;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OutfitSemiBold.ttf");
  font-family: "Outfit";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OutfitMedium.ttf");
  font-family: "Outfit";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/AlegreyaRomanBold.ttf");
  font-family: "Alegreya";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/Montserrat-Regular.ttf");
  font-family: "Montserrat";
}
