body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
a {
  display: block;
}
ul {
  margin: 0;
  margin-inline: 0 !important;
  padding: 0;
  list-style: none;
}
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}
input {
  background-color: transparent;
  width: 100%;
}
input:focus {
  outline: none;
}
.alice-carousel__dots-item {
  cursor: pointer;
}

.nav-list { list-style: none; display: flex; align-items: center; gap: 36px;}

.hero-section { height: 540px; width: 100%; background-image: url('../../public/images/hero-bg.png'); display: flex; gap: 209px; position: relative; padding-left: 78px; padding-top: 62px; overflow: hidden; padding-right: 141px; align-items: center; background-size: cover; background-repeat: no-repeat;}
.banner-1 { height: 540px; width: 100%; background-image: url('../assets/banner/banner2.jpg'); display: flex; gap: 209px; position: relative; padding-left: 78px; padding-top: 62px; overflow: hidden; padding-right: 141px; align-items: center; background-size: cover; background-repeat: no-repeat;}
.banner-2 { height: 540px; width: 100%; background-image: url('../assets/banner/banner3-mobile.jpg'); display: flex; gap: 209px; position: relative; padding-left: 78px; padding-top: 62px; overflow: hidden; padding-right: 141px; align-items: center; background-size: cover; background-repeat: no-repeat;}
.banner-3 { height: 540px; width: 100%; background-image: url('../../public/images/banner/banner-3.jpeg'); display: flex; gap: 209px; position: relative; padding-left: 78px; padding-top: 62px; overflow: hidden; padding-right: 141px; align-items: center; background-size: cover; background-repeat: no-repeat;}
.banner-4 { height: 540px; width: 100%; background-image: url('../assets/banner/banner1.jpg'); display: flex; gap: 209px; position: relative; padding-left: 78px; padding-top: 62px; overflow: hidden; padding-right: 141px; align-items: center; background-size: cover; background-repeat: no-repeat;}
.hero-section-2 { height: 540px; width: 100%; background-image: url('../../public/images/hero-bg.png'); display: flex; gap: 0px; position: relative; padding-left: 78px; padding-top: 62px; overflow: hidden; padding-right: 100px; align-items: center; background-size: cover; background-repeat: no-repeat;}

.bg-2 { background-image: url('../../public/images/img/bg-2.png'); height: 805px; width: 100%; background-repeat: no-repeat; background-size: cover; margin-top: -8px; position: relative;}
.bg-2-content { position: absolute; border: 7px solid #F47216; background-color: #FFFFFF; color: #000000; padding: 40px 20px; max-width: 363px; width: 100%; border-radius: 16px; bottom: 92px; left: 130px;}
.bg-2-content .wrapper { background-image: url('../../public/images/img/bg-wrapper.png'); height: 100px; width: 101px; position: absolute; bottom: -100px; left: 50%; transform: translateX(-50%);}

@media(max-width: 768px) {
  .bg-2 { height: 300px; width: 100%; background-repeat: no-repeat; background-size: cover; margin-top: -8px; position: relative;}
  .banner-1 { height: 140px; object-fit: cover; background-image: url('../assets/banner/banner2-mobile.jpg');}
  .banner-2 { height: 140px; object-fit: cover;  background-image: url('../assets/banner/banner3.jpg');}
  .banner-3 { height: 140px; object-fit: cover; }
  .banner-4 { height: 140px; object-fit: cover;  background-image: url('../assets/banner/banner1-mobile.jpg');}
  .hero-section { height: 270px; width: 100%; background-image: url('../../public/images/hero-bg.png'); display: flex; gap: 20px; position: relative; padding-left: 10px; padding-top: 20px; overflow: hidden; padding-right: 10px; align-items: center;}
  .hero-section-2 { height: 740px; width: 100%; background-image: url('../../public/images/hero-bg.png'); display: flex; gap: 20px; position: relative; flex-direction: column; padding-left: 10px; padding-top: 20px; overflow: hidden; padding-right: 10px; align-items: center;}
  .bg-2-content .wrapper { display: none;}
  .bg-2-content { position: absolute; padding: 14px; max-width: 100%; bottom: -200px; left: 0;}
  .nav-list {  display: block; position: fixed; width: 200px; right: 0; bottom: 0; top: 0; background-color: #FFF; z-index: 1000; transition: ease 0.5s; padding: 10px 20px;}
  .nav-list a { padding: 10px 0;}
  .overlay {  background-color: rgba(0, 0, 0, 0.66); position: fixed; top: 0; left: 0; bottom: 0; z-index: 990; transition: ease 0.5s; }
}
.hero-slider { overflow: hidden;}
.hero-slider .slick-dots { bottom: 40px;}
.hero-slider .slick-dots li { height: 25px; width: 25px;}
.hero-slider .slick-dots li button { height: 25px; width: 25px; padding: 0;}
.hero-slider .slick-dots li button:before { height: 25px; width: 25px; opacity: 1; content: ""; background-color: #C07F40; border-radius: 50%;}
.hero-slider .slick-dots li.slick-active button:before { height: 25px; width: 25px; opacity: 1; content: ""; background-color: #FFFFFF; border-radius: 50%;}

@media(max-width: 768px) {
  .hero-slider .slick-dots li { height: 14px; width: 14px;}
  .hero-slider .slick-dots li button { height: 14px; width: 14px; padding: 0;}
  .hero-slider .slick-dots li button:before { height: 14px; width: 14px;}
  .hero-slider .slick-dots li.slick-active button:before { height: 14px; width: 14px; }
}

.gallery-slider { overflow: hidden;}
.gallery-slider .slick-slide { padding-left: 10px;}

.party-box { position: relative; transition: all 0.5s;}
.party-box .party-img { transition: all 0.5s; object-fit: cover;}
.party-img { height: 211px; object-fit: cover; width: 100%;}
/* .party-box:hover .party-img { display: block;} */

.footer-right { position: absolute; top: -190px; right: 0px; display: flex; flex-direction: column; gap: 32px;}
.sub-box { background-image: url('/public/images/img_group_16.svg'); width: 385px; height: 157px;}

.contact-box { border: 1px solid #F47216; background-color: #ffffff; padding: 25px 15px; border-radius: 16px; width: 212px; margin-left: 36px; display: flex; flex-direction: column; gap: 15px;}
.contact-box input { border: 1px solid #DCDCDC; outline: none; padding: 6px 10px; font-family: 'Outfit'; font-weight: 400; font-size: 14px; line-height: 28px; border-radius: 8px; background-color: 8px; color: #000;}
.contact-box button { background-color: #F47216; color: #fff; padding: 6px 0; width: 100%; border-radius: 8px;}

.media-slider { overflow: hidden; margin-top: 100px;}
.media-slider .slick-slide { padding-left: 30px; }

@media(max-width: 768px) {
  .hero-slider .slick-dots { bottom: 15px;}
  .footer-right { position: unset; align-items: center; }
  .party-box .party-img { display: block; transition: all 0.5s; height: 220px; width: 210px; object-fit: cover;}
  .media-slider .slick-slide { padding-left: 0px; }
  .media-slider { margin-top: 30px;}
  .contact-box { margin: 0;}
}

.jan-slider { margin-top: -10px; width: 100%; overflow: hidden;}
.jan-section { background-image: linear-gradient(91deg, #060605, #504d4be8, #24211fce); display: flex; position: relative;}

.text-line { position: absolute; background-image: url('../../public/images/img/line.svg'); height: 4px; width: 104px; bottom: 22px;}

@media(max-width: 768px) {
  .jan-section { flex-direction: column;}
  .text-line { position: absolute; background-image: url('../../public/images/img/line.svg'); height: 4px; width: 104px; bottom: -4px;}
}
